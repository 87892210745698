<template>
  <div>
    <div class="body-main">
      <div class="order-main clearfix">
        <div class="slider l">
          <h1>订单中心</h1>
          <ul class="nav-menu">
              <li @click="oderActive='我的订单'" :class="{'active':oderActive=='我的订单'}" class="clearfix"><p class="nav-name l">我的订单</p><span class="imv2-arrow1_r r"></span></li>
       <!--       <li @click="oderActive='我的优惠券'" :class="{'active':oderActive=='我的优惠券'}"  class="clearfix"><p class="nav-name l">我的优惠券</p><span class="imv2-arrow1_r r"></span></li>
       -->   </ul>
        </div>
        <div v-if="oderActive=='我的订单'" class="right-container l">
          <div class="right-title">
            <h2>我的订单</h2>
            <ul><li @click="topActive=item" v-for="(item, index) in topnav" :key="index" :class="{'action':topActive==item}" class=""><a href="javascript:void(0)">{{item}}<i class="js-all-num"></i></a></li></ul>
          </div>
          <div class="myOrder">
            <ul class="myOrder-list">
              <li v-for="(item, index) in OrderList" :key="index">
                <p class="myOrder-number">
                  <i class="imv2-receipt"></i>订单编号：{{item.id}}
                  <span class="date">{{item.addtime}}</span>
                <!--  <a href="//www.imooc.com/help" target="_blank" class="myfeedback r">售后帮助</a>-->
                </p>
                <div class="myOrder-course clearfix">
                  <dl class="course-del l">
                    <dd class="clearfix" v-for="(item2, index) in item.orderitemList" :key="index2">
                      <a href="#/" class="l"><img class="l" :src="item2.imagesmall" width="160" height="90"/></a>
                      <div class="del-box l">
                        <a href="#/"><p class="course-name">{{item2.coursename}}</p></a>
                        <p class="price-btn-box clearfix"><span class="l course-little-price">￥{{item2.cusamountjs}}</span></p>
                      </div>
                      <div class="fenxiao_refund"></div>
                    </dd>

                  </dl>
                  <!-- 没有使用优惠券 -->
                  <div class="course-money l pt36"><div class="wrap"><div class="total-box"><p class="type-price"><span class="RMB">¥</span>{{item.cusamountjs}}</p></div></div></div>
                  <div v-if="item.status=='已支付'" class="course-action l">
                    <a class="pay-now" :href="`#/dd?id=${item.ordercd}`">立即支付</a>
                    <a class="order-cancel" href="javascript:void(0);" @click="cancellationOder(item.ordercd)">取消订单</a>
                  </div>
                  <div v-if="item.status!=='已支付'" class="course-action l">
                    <p class="order-finish">已完成</p>
                    <p class="pay-way">微信支付</p>
                  </div>
                  <div v-if="item.status=='已失效'" class="course-action l"><p class="order-finish">已失效</p></div>
                </div>
              </li>
              </ul>
          </div>
        </div>
         <div v-if="oderActive=='我的优惠券'" class="right-container l">
          <div class="right-title">
            <h2>我的优惠卷</h2>
            <ul><li @click="tActive=item" v-for="(item, index) in ['未使用','已使用']" :key="index" :class="{'action':tActive ==item}" class=""><a href="javascript:void(0)">{{item}}<i class="js-all-num"></i></a></li></ul>
          </div>
          <div class="myOrder">
            <ul class="myOrder-list">
              <li v-for="(item, index) in OrderList" :key="index">
                <p class="myOrder-number">
                  <i class="imv2-receipt"></i>优惠卷号:{{item.ordercd}}
                  <span class="date">2022-02-05 13:33:23</span>
                  <a href="#/help" target="_blank" class="myfeedback r">售后帮助</a>
                </p>
                <div class="myOrder-course clearfix">
                  <dl class="course-del l">
                    <dd class="clearfix">
                      <a href="https://coding.imooc.com/class/474.html" class="l"><img class="l" src="https://szimg.mukewang.com/60223b5d09e0c94412000676-160-90.jpg" width="160" height="90"/></a>
                      <div class="del-box l">
                        <a href="https://coding.imooc.com/class/474.html"><p class="course-name">{{item.coursename}}</p></a>
                        <p class="price-btn-box clearfix"><span class="l course-little-price">￥{{item.srcprice}}</span></p>
                      </div>
                      <div class="fenxiao_refund"></div>
                    </dd>
                  </dl>
                  <div class="course-action l"><a class="pay-now" href="#/list">立即使用</a></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../ulti/ajax'
export default {
  data(){
    return{
      OrderList:[],
      topnav:['全部','未支付','已完成','已失效'],
      topActive:"全部",
      dataList:[],
      oderActive:"我的订单",
      tActive:"未使用",
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },



    }
  },
  watch: {
    tActive:function(newVal, oldVal){
     this.renderData()
    },
    oderActive:function(){
     this.renderData()
    },
    topActive:function(){
     this.renderData()

    }
  },
  methods: {
    // 取消订单
    async cancellationOder(oderId){
     let data =  (await api.cancellationOder(oderId));
     this.renderData()
    },
      // 更新列表
    async renderData(){
     this.OrderList = (await api.getOrderList(this.queryParams)).data.rows;
    }
  },
  async created(){
     this.renderData()
  }
};
</script>

<style>
@import url('../../assets/css/oder.css');
</style>
